import { TelemetryDeck } from '@telemetrydeck/sdk';

let testMode = window.location.href.includes('//localhost') || window.location.href.includes(':1234');

const td = new TelemetryDeck({ app: '61D91E9C-9B88-4BA0-833D-BCBE7E7507FD', user: 'anonymous' });
if (testMode) {
	td.signal({ isTestMode: 'true' })
} else {
	td.signal()
}

let buttons = document.querySelectorAll<HTMLAnchorElement>('.tdLink');

buttons.forEach((button: HTMLAnchorElement) => {
	button.addEventListener('click', (event) => {
		event.preventDefault()
		let tdEvent = button.dataset.tdEvent
		if (tdEvent) {
			if (testMode) {
				td.signal({
					route: `event/${tdEvent}`,
					event: tdEvent,
					isTestMode: 'true'
				})
			} else {
				td.signal({
					route: `event/${tdEvent}`,
					event: tdEvent,
				})
			}
		}

		setTimeout(function () {
			let href = button.getAttribute('href')
			if (!href) return
			window.location.href = href
		}, 1000)
	});
});


// rotation with scroll

const rotateElements = document.querySelectorAll<HTMLElement>('.scroll-rotate');

document.addEventListener('scroll', () => {
	rotateElements.forEach((elem: HTMLElement) => {
		const value = elem.getBoundingClientRect().top * -0.05;
		elem.style.rotate = `${value}deg`
	})
})
